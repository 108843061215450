.contact {
  height: 100vh;
  width: 100vw;
  background-color: #293c3a;
  display: flex;
  justify-content: center;
  align-items: center;
}

form {
  width: 50%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

/* input and textarea */

.input-container {
  width: calc(50% - 8px);
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
}

.textarea-container {
  width: 100%;
}

.input-container input {
  background-color: transparent;
  height: 48px;
  border-radius: 24px;
  border: 1px solid #f7f9f8;
  outline: none;
  opacity: 0.6;
  padding: 0px 24px;
  color: #f7f9f8;
}

.input-container textarea{
  background-color: transparent;
  height: 144px;
  border-radius: 24px;
  border: 1px solid #f7f9f8;
  outline: none;
  opacity: 0.6;
  padding: 24px;
  color: #f7f9f8;
  resize: none;
}

.input-container label {
  position: absolute;
  pointer-events: none;
  transform: translate(24px, 5px) scale(1.5);
  transform-origin: top left;
  transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  color: #f7f9f8;
  font-size: 16px;
  background-color: #293c3a;
  padding: 4px;
  opacity: 0.6;
}

.input-container:focus-within input {
  opacity: 1;
}

.input-container:focus-within textarea {
  opacity: 1;
}

.input-container:focus-within label {
  transform: translate(24px, -14px) scale(1);
  color: #f7f9f8;
  opacity: 1;
}

.input-container .filled {
  transform: translate(24px, -14px) scale(1);
  color: #f7f9f8;
  opacity: 1;
}

/* radio */

.radio-container {
  height: 48px;
  width: calc(50% - 8px);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
}

.radio-container p {
  color: #f7f9f8;
  font-size: 16px;
  opacity: 0.6;
  margin: 0;
}

.switch-field {
	display: flex;
}

.switch-field input {
	position: absolute !important;
	clip: rect(0, 0, 0, 0);
	height: 1px;
	width: 1px;
	border: 0;
	overflow: hidden;
}

.switch-field label {
	color: #f7f9f8;
	font-size: 14px;
	line-height: 1;
	text-align: center;
	padding: 8px 16px;
	margin-right: -1px;
	border: 1px solid #f7f9f8;
  opacity: 0.6;
	box-shadow: inset 0 1px 3px #f7f9f8, 0 1px #f7f9f8;
  transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}

.switch-field label:hover {
	cursor: pointer;
}

.switch-field input:checked + label {
	box-shadow: none;
  opacity: 1;
}

.switch-field label:first-of-type {
	border-radius: 24px 0 0 24px;
}

.switch-field label:last-of-type {
	border-radius: 0 24px 24px 0;
}

/* button */

.submit-button {
  width: 100%;
  background-color: #f7f9f8;
  height: 48px;
  border-radius: 24px;
  border: 1px solid #f7f9f8;
  outline: none;
  opacity: 0.6;
  padding: 0px 24px;
  color: #293c3a;
  cursor: pointer;
  transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  font-size: 24px;
}

.submit-button:hover {
  opacity: 1;
}