.about{
  height: 100vh;
  width: 100vw;
  background-color: #293c3a;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  color: #f7f9f8;
  text-align: center;
}

.about-photo, .about-content{
  width: 30%;
}

.fake-img {
  background-color: orange;
  width: 300px;
  height: 400px;
}