div.nav {
  position: fixed;
  height: 144px;
  left: 48px;
  top: calc(50vh - 72px);
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: #f7f9f8;
  border: 1px solid #f7f9f8;
  border-radius: 24px;
}